<template>
    <div class="clip-main">
        <iframe
            width="100%"
            style="height: 100%; border: none"
            :src="link"
        ></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            link: `https://c.hotkeey.cn/${this.$store.state.user.token}`,
        };
    },
    created() {
        this.link = `https://c.hotkeey.cn/detail/${this.$route.params.pojectId}/${this.$store.state.token}`;
    },
};
</script>

<style lang="less" scoped>
.clip-main {
    height: 100%;
}
</style>